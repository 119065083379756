<!-- Register -->
<div class="user-form-area">
    <div class="container-fluid p-0">
        <div class="row m-0 align-items-center">
            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-img">
                    <img src="assets/img/resgister-bg.jpg" alt="User">
                </div>
            </div>

            <div class="col-lg-6 p-0 col-md-12">
                <div class="user-content">
                    <div class="top">
                        <h2>Register</h2>

                        <form>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name">
                            </div>

                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email">
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Phone">
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Subject">
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password">
                            </div>

                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Confirm Password">
                            </div>

                            <button type="submit" class="btn">Register Here</button>
                        </form>
                    </div>

                    <div class="end">
                        <ul>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register -->